import React, {useEffect} from 'react';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import ReactDOM from 'react-dom';
import Login from './shared/user/Login';
import ForgetPassword from './shared/user/ForgetPassword';
import LoginMobile from './shared/user/LoginMobile';
import ResetPassword from './shared/user/ResetPassword';
import PasswordOtp from './shared/user/PasswordOtp';
import Signup from './shared/user/Signup';
import Varify from './shared/user/VarifyMobile';
import TermsConditions from './shared/user/TermsConditions';
import Dashboard from './admin/dashboard/Dashboard';
import ManageEquipment from './admin/manageEquipment/ManageEquipment';
import AddNewEquipment from './admin/manageEquipment/AddNewEquipment';
import EditEquipment from './admin/manageEquipment/EditEquipment';
import Orders from './admin/orders/Orders';
import QuoteRequest from './admin/quoteRequest/QuoteRequest';
import Subscriptions from './admin/subscriptions/Subscriptions';
import HireEquipment from './hireEquipment/HireEquipment';
import ListEquipment from './hireEquipment/ListEquipment';
import AboutUs from './hireEquipment/AboutUs';
import ContactUs from './hireEquipment/ContactUs';
import Cart from './hireEquipment/Cart';
import ViewEquipmentDetail from './hireEquipment/ViewEquipmentDetail';
import RegisterOrganization from './admin/RegisterOrganization';
import Logout from './shared/user/Logout';
import Help from './admin/Help';
import Details from './admin/Details';
import AppAbout from './admin/AppAbout';
import HomeBlogs from './hireEquipment/HomeBlogs';
import TermsAndConditions from './hireEquipment/TermsAndConditions';
import PrivacyPolicy from './hireEquipment/PrivacyPolicy';
import LegalInfo from './hireEquipment/LegalInfo';
import HowWorks from './hireEquipment/HowWorks';
import HelpHome from './hireEquipment/HelpHome';
import FaqsHome from './hireEquipment/FaqsHome';
import Message from './hireEquipment/Message';
import Notification from './hireEquipment/Notification';
import HireOrders from './hireEquipment/Orders';
import CurrentOrders from './hireEquipment/CurrentOrders';
import Account from './hireEquipment/Account';
import OrderSummary from './hireEquipment/OrderSummary';
import { BrowserRouter, Routes, Route,} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import AdminOrderSummary from './admin/orders/OrderSummary';
import AdminAccount from './admin/AdminAccount';
import AdminNotification from './admin/AdminNotification';
import PageNotFound from './shared/PageNotFound';
import Favourites from './hireEquipment/Favourites';
import { CountContext } from './hireEquipment/CountContext'; 
import Messages from './admin/messages/Messages';

const MyComponent = () => {

  const [favCount, setFavCount] = React.useState();
  const [cartsTotal, setCartsTotal] = React.useState();
  const [unreadTotal, setUnreadTotal] = React.useState(0);

  useEffect(() => {
   unreadCount()
  }, []);

  const favouriteCount = () => {
    const params = {
      headers: {
          "Content-Type": 'application/json',
          "Access-Control-Allow-Origin": '*',
          "token": localStorage.getItem('token')
      }
  };
    axios.get(`${appConstant.API_URL}/bx_block_favourites/favourites/favourite_count`, params)
        .then(res => {
            if (res?.status === 200) {
                if(res?.data?.favourite_count){
                   //localStorage.setItem('favouriteTotal', res?.data?.favourite_count);
                  setFavCount(res?.data?.favourite_count)
                }else {
                  // localStorage.setItem('favouriteTotal', 0);
                  setFavCount(0)
                }
            }else {
            }
        })
        .catch(error => {
        });
}

const cartCount = () => {
  const params = {
    headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
    }
};
  axios.get(`${appConstant.API_URL}/bx_block_order_management/orders/cart_item_count`, params)
      .then(res => {
          if (res?.data?.status === 200) {
              if(res?.data?.data){
                 // localStorage.setItem('cartTotal', res?.data?.data);
                 setCartsTotal(res?.data?.data);
              }else {
                  // localStorage.setItem('cartTotal', 0);
                  setCartsTotal(0);
              }
          }else {
          }
      })
      .catch(error => {
      });
}

const unreadCount = () => {
  const params = {
    headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": '*',
        "token": localStorage.getItem('token')
    }
};
  axios.get(`${appConstant.API_URL}/bx_block_chat/chats/mychats`, params)
      .then(res => {
          if (res?.status === 200) {
              const systemChats = res?.data?.data?.system_user_chat || [];
              const otherChats = res?.data?.data.other_user_chat || [];
              
              
              const sumUnreadCount = (chats) => {
                  return chats.reduce((total, chat) => {
                      return total + (chat.attributes.unread_count || 0);
                  }, 0);
              };
            
              const totalUnread = sumUnreadCount(systemChats) + sumUnreadCount(otherChats);
              setUnreadTotal(totalUnread);
          } else {
              setUnreadTotal(0);
          }
      });
};
  return (
    
      <BrowserRouter>
      <CountContext.Provider value={{ favCount, favouriteCount, cartsTotal, cartCount, unreadTotal, unreadCount }}>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forget-password" element={<ForgetPassword />}></Route>
          <Route path="/login-mobile" element={<LoginMobile />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/password-otp" element={<PasswordOtp />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/varify" element={<Varify />}></Route>
          <Route path="/terms-conditions" element={<TermsConditions />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/manage-equipment" element={<ManageEquipment />}></Route>
          <Route path="/messages" element={<Messages />}></Route>
          <Route path="/manage-equipment/add-new-equipment" element={<AddNewEquipment />}></Route>
          <Route path="/manage-equipment/edit-equipment/:id" element={<EditEquipment />}></Route>
          <Route path="/orders" element={<Orders />}></Route>
          <Route path="/orders/order-summary/:id" element={<AdminOrderSummary />}></Route>
          <Route path="/quote-request" element={<QuoteRequest />}></Route>
          <Route path="/subscriptions" element={<Subscriptions />}></Route>
          <Route path="/home/hire-equipment" element={<HireEquipment />}></Route>
          <Route path="/home/list-equipment" element={<ListEquipment />}></Route>
          <Route path="/home/orders/" element={<HireOrders />}>
            <Route path=":type" element={<HireOrders />}></Route>
            {/*<Route path="" element={<HireOrders />}></Route>*/}
          </Route>
          <Route path="/home/orders/current-orders/:orderId" element={<CurrentOrders />}></Route>
          <Route path="/home/about-us" element={<AboutUs />}></Route>
          <Route path="/home/contact-us" element={<ContactUs />}></Route>
          <Route path="/home/cart" element={<Cart />}></Route>
          <Route path="/home/favourites" element={<Favourites />}></Route>
          <Route path="/home/hire-equipment/view-equipment-detail/:id" element={<ViewEquipmentDetail />}></Route>
          <Route path="/register-organization" element={<RegisterOrganization />}></Route>
          <Route path="/home/orders/previous-orders/order-summary/:id" element={<OrderSummary />}></Route>
          <Route path="/home/account" element={<Account />}></Route>
          <Route path="/logout" element={<Logout />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/app-version" element={<Details />}></Route>
          <Route path="/app-about" element={<AppAbout />}></Route>
          <Route path="/home/blogs" element={<HomeBlogs />}></Route>
          <Route path="/home/terms-and-conditions" element={<TermsAndConditions />}></Route>
          <Route path="/home/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/home/legal-information" element={<LegalInfo />}></Route>
          <Route path="/home/how-it-works" element={<HowWorks />}></Route>
          <Route path="/home/help" element={<HelpHome />}></Route>
          <Route path="/home/faqs" element={<FaqsHome />}></Route>
          <Route path="/home/message" element={<Message />}></Route>
          <Route path="/home/notification" element={<Notification />}></Route>
          <Route path="/account" element={<AdminAccount />}></Route>
          <Route path="/notification" element={<AdminNotification />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
        </CountContext.Provider>
      </BrowserRouter>
  )
};

ReactDOM.render(
   <MyComponent />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
